import { convertToStringFormat, convertToSeconds } from 'lib/helpers/convert-duration/convertDuration';

import ITemplate from 'lib/Intefaces/ITemplate';

import { nanoid } from 'nanoid';

import { stringInputs, predefinedConstants, inputValues, crossLanguageFields } from './constants';

const checkTypes = (template: ITemplate, checkValue: string) => {
  if (stringInputs.includes(checkValue)) {
    return { [checkValue]: Object(template)[checkValue] || '' };
  }

  if (checkValue === 'cookieMaxAgeSeconds') {
    return {
      [checkValue]: Object(template)[checkValue] === null ? '' : convertToStringFormat(Object(template)[checkValue]),
    };
  }

  if (checkValue === 'usesNonCookieAccess') {
    return { [checkValue]: Object(template)[checkValue] };
  }

  if (checkValue === 'deviceStorage') {
    return {
      [checkValue]: {
        disclosures: Object(template)[checkValue].disclosures.map((disclosure: any) => {
          return {
            ...disclosure,
            id: nanoid(),
            type: disclosure.type.charAt(0).toUpperCase() + disclosure.type.slice(1),
            maxAgeSeconds: disclosure.maxAgeSeconds !== null ? convertToStringFormat(disclosure.maxAgeSeconds) : '',
          };
        }),
      },
    };
  }

  const responseValues =
    checkValue === 'thirdCountryTransfer' || checkValue === 'locationOfProcessing'
      ? Object(template)[checkValue] !== ''
        ? Object(template)[checkValue].split(',')
        : []
      : Object(template)[checkValue].map((el: string) => el.trim()) || [];
  const valuesForSpecificLanguage = Object(predefinedConstants)[checkValue][template.language];

  return {
    [checkValue]: responseValues.map(
      (element: string) =>
        Object.keys(valuesForSpecificLanguage).find(
          (key) => valuesForSpecificLanguage[key].trim() === element.trim(),
        ) || element,
    ),
  };
};

export const setInitialValuesForEdit = (templates: Array<ITemplate>) =>
  templates.map((template) =>
    Object.keys(inputValues).reduce(
      (acc, curr) => ({
        ...acc,
        ...checkTypes(template, curr),
      }),
      inputValues,
    ),
  );

const checkTypesBeforePosting = (template: ITemplate, checkValue: string, englishVersion?: ITemplate) => {
  if (stringInputs.includes(checkValue)) {
    if (!crossLanguageFields.includes(checkValue)) {
      return { [checkValue]: Object(template)[checkValue] || Object(englishVersion)[checkValue] };
    }

    return { [checkValue]: Object(template)[checkValue] };
  }

  if (checkValue === 'usesNonCookieAccess') {
    return { [checkValue]: Object(template)[checkValue] || Object(englishVersion)[checkValue] };
  }

  if (checkValue === 'cookieMaxAgeSeconds') {
    if (Object(template)[checkValue] === '') {
      return { [checkValue]: null };
    }
    return { [checkValue]: convertToSeconds(Object(template)[checkValue]) };
  }

  if (checkValue === 'deviceStorage') {
    const { disclosures } = Object(template)[checkValue];
    return {
      [checkValue]: {
        disclosures: disclosures.map((disclosure: any) => {
          const { newStorage, id, __typename, ...disc } = disclosure;
          return {
            ...disc,
            maxAgeSeconds:
              disc.type !== 'Cookie' && disc.maxAgeSeconds === '' ? null : convertToSeconds(disc.maxAgeSeconds),
            type: disc.type.toLowerCase(),
          };
        }),
      },
    };
  }

  const values = Object(template)[checkValue];

  return {
    [checkValue]: values.map((value: any) => Object(predefinedConstants)[checkValue][template.language][value]),
  };
};

export const carryOverValues = (template: ITemplate, templates: ITemplate[], carryOverLanguages: string[]) => {
  if (carryOverLanguages.includes(template.language)) {
    const engTemplate = templates.find((t) => t.language === 'en') as ITemplate;

    return {
      ...template,
      dataProcessor: engTemplate.dataProcessor,
      nameOfProcessingCompany: engTemplate.nameOfProcessingCompany,
      addressOfProcessingCompany: engTemplate.addressOfProcessingCompany,
      dataProtectionOfficer: engTemplate.dataProtectionOfficer,
      privacyPolicyURL: engTemplate.privacyPolicyURL,
      cookiePolicyURL: engTemplate.cookiePolicyURL,
      iabId: engTemplate.iabId,
      iabv2Id: engTemplate.iabv2Id,
      dataRecipientsList: engTemplate.dataRecipientsList,
      optOutUrl: engTemplate.optOutUrl,
      defaultCategorySlug: engTemplate.defaultCategorySlug,
    };
  }
  return template;
};

export const prepareDataBeforePost = (templates: ITemplate[]) => {
  const englishVersion = templates.find((template) => template.language === 'en');

  return templates.map(
    (template: ITemplate) =>
      // @ts-ignore
      Object.keys(inputValues).reduce(
        (acc: {}, curr: string) => ({
          ...acc,
          ...checkTypesBeforePosting(template, curr, englishVersion),
        }),
        inputValues,
      ) as ITemplate,
  );
};

export const validateData = (templates: Array<ITemplate>) => {
  console.log(`=== Validating ${templates[0]?.dataProcessor} (${templates[0]?.templateId}) ===`);
  const validation = templates.map((template: ITemplate) =>
    Object.keys(template).reduce(
      (acc: {}, curr: string) =>
        curr in predefinedConstants && Object(template)[curr].length > 0
          ? {
              ...acc,
              [curr]: Object(template)[curr].some((element: string) => {
                const keyExistsInPredefined = Object.keys(
                  Object(predefinedConstants)[curr][template.language],
                ).includes(element);

                // Legal Team is using it to indentidy invalid values
                if (!keyExistsInPredefined) {
                  console.log(`[${template.language.toUpperCase()}][${curr}]: ${element}`);
                }

                return !keyExistsInPredefined;
              }),
            }
          : acc,
      {},
    ),
  );
  console.log('======================================================');

  return !validation.flatMap((template) => [...Object.values(template)]).includes(true);
};

export default {
  inputValues,
  setInitialValuesForEdit,
  prepareDataBeforePost,
  validateData,
};
